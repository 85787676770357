<template>
  <div>
    <div class="supply-chain-content">
      <div class="container is-max-widescreen">

        <div class="columns is-tablet is-align-items-flex-start">
          <div class="column is-three-fifths-tablet is-flex is-flex-direction-column is-align-items-center">
            <div class="card mb-5">
              <div class="card-content">
                <p class="supply-chain-header-title">
                  {{ $t('supply.logistic') }}
                </p>
              </div>
            </div>
            <div class="columns is-mobile is-justify-content-center w-100">
              <div class="column is-6-mobile is-4-tablet">
                <div class="card">
                  <b-icon icon="arrow-right" class="icon-arrow"/>
                  <div class="supply-chain-img">
                    <img src="/assets/img/supply/supply-4.png" :alt="$t('supply.harvest_team')">
                  </div>
                  <div class="card-content">
                    <p class="supply-chain-title">{{ $t('supply.harvest_team') }}</p>
                  </div>
                </div>
              </div>
              <div class="column is-6-mobile is-4-tablet">
                <div class="card">
                  <b-icon icon="arrow-right" class="icon-arrow"/>
                  <div class="supply-chain-img">
                    <img src="/assets/img/supply/supply-5.png" :alt="$t('supply.farm')">
                  </div>
                  <div class="card-content">
                    <p class="supply-chain-title">{{ $t('supply.farm') }}</p>
                  </div>
                </div>
              </div>
              <div class="column is-6-mobile is-4-tablet">
                <div class="card">
                  <b-icon icon="arrow-right" class="icon-arrow"/>
                  <div class="supply-chain-img">
                    <img src="/assets/img/supply/supply-6.png" :alt="$t('supply.storage')">
                  </div>
                  <div class="card-content">
                    <p class="supply-chain-title">{{ $t('supply.storage') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-two-fifths-tablet is-flex is-flex-direction-column is-align-items-center">
            <div class="card mb-5">
              <div class="card-content">
                <p class="supply-chain-header-title">
                  {{ $t('supply.payment') }}
                </p>
              </div>
            </div>
            <div class="columns is-mobile w-100">
              <div class="column is-6-mobile">
                <div class="card has-background-primary">
                  <b-icon icon="arrow-right" class="icon-arrow inverse"/>
                  <div class="card-content">
                    <p class="supply-chain-sgara-title">Sgara</p>
                    <div class="card">
                      <div class="supply-chain-img-bg">
                        <div class="supply-chain-img">
                          <img src="/assets/img/harvest/harvest-3.png" :alt="$t('supply.direct')">
                        </div>
                      </div>
                      <div class="card-content">
                        <p class="supply-chain-title">{{ $t('supply.direct') }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6-mobile">
                <div class="card">
                  <div class="supply-chain-img custom-image">
                    <img src="/assets/img/banner/banner-image.png" :alt="$t('supply.farmer')">
                  </div>
                  <div class="card-content">
                    <p class="supply-chain-title">{{ $t('supply.farmer') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "HarvestStep2",
}
</script>

<style lang="scss" scoped>

.supply-chain-header-title {
  color: #128FE3;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}

.columns {
  align-items: flex-start;
}

.supply-chain-content {
  background-color: #E8ECFF;
  color: #363636;
  position: relative;
  padding-bottom: 3rem;
  //margin-top: 50px;

  //@media (max-width: 768px) {
  //  margin-bottom: 6rem;
  //}

  .card {
    height: 100%;
    position: relative;

    > .icon-arrow {
      position: absolute;
      z-index: 10;
      background-color: #128FE3;
      color: #FFFFFF;
      width: 38px;
      height: 38px;
      border-radius: 100%;
      right: 0;
      top: 70px;
      transform: translate(50%, 0);

      &.inverse {
        background-color: #FFFFFF;
        color: #128FE3;
        border: 2px solid #128FE3;
      }
    }
  }

  .supply-chain-title {
    color: #363636;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: .5rem;
    text-align: center;
  }

  .supply-chain-sgara-title {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: .25rem;
  }

  .supply-chain-img {
    position: relative;
    background-color: #ffffff;
    z-index: 1;
    display: flex;
    justify-content: center;
    border-radius: 24px 24px 0 0;
    align-items: center;
    height: 120px;

    > img {
      height: 80px;
    }

    &.custom-image {
      height: 150px;
      > img {
        height: 150px;
      }
    }
    //> svg {
    //  position: absolute;
    //  left: 0 ;
    //  right: 0;
    //  bottom: 0;
    //  transform: translateY(50%);
    //  z-index: -1;
    //}
  }

  .supply-chain-img-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 120px;
    padding-top: 1rem;
    .supply-chain-img {
      background-color: #128FE3;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      img {

        width: 60px;
        height: 60px;
      }
    }
  }

  .card-content {
    padding: .5rem;
  }
}
</style>
